import {getType} from "typesafe-actions";

import {agoraActions, AgoraActionType} from "./agoraActions";
import analyticsService from "../../analytics/AnalyticsService";

const trackedActions = [
	agoraActions.openAgoraWindow.request,
	agoraActions.openAgoraWindow.success,
	agoraActions.openAgoraWindow.failure,
	agoraActions.closeAgoraWindow,

	agoraActions.openInvitationDialog,
	agoraActions.closeInvitationDialog,

	agoraActions.userCreatedMeeting,
].map(getType);

const properties = (action: AgoraActionType) => {
	switch (action.type) {
		case getType(agoraActions.openInvitationDialog):
			return action.payload.invitationCreator;
		default:
			return null;
	}
};

export const agoraEvents = analyticsService.trackReduxActions(trackedActions, properties);
