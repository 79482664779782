import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {AgoraWindowAction} from "./types";
import {AgoraCallActivity, AgoraChannel, CallType} from "@sense-os/goalie-js";

export const agoraActions = {
	// Open Agora window actions
	openAgoraWindow: createAsyncAction(
		"agora/openAgoraWindow",
		"agora/openAgoraWindowSucceed",
		"agora/openAgoraWindowFailed",
	)<
		{
			windowAction: AgoraWindowAction;
			meetingText?: string;
			channelId?: string;
			callType?: CallType;
			channelCreatorId?: number;
		},
		void,
		Error
	>(),

	// Close Agora window action
	closeAgoraWindow: createAction("agora/closeAgoraWindow")(),

	// Reset Agora window state action
	resetAgoraWindowState: createAction("agora/resetAgoraWindowState")(),

	// Open Agora invitation dialog action
	openInvitationDialog: createAction(
		"agora/openInvitationDialog",
		(invitationCreator?: number, invitationLink?: string, channelId?: string, callType?: CallType) => ({
			invitationCreator,
			invitationLink,
			channelId,
			callType,
		}),
	)(),

	// Close Agora invitation dialog action
	closeInvitationDialog: createAction("agora/closeInvitationDialog")(),

	// Agora created meeting action
	userCreatedMeeting: createAction("agora/userCreatedMeeting", (createdLink: string) => ({createdLink}))(),

	// Agora meeting create channel action
	createAgoraChannel: createAsyncAction(
		"agora/createAgoraChannel",
		"agora/createAgoraChannelSucceed",
		"agora/createAgoraChannelFailed",
	)<
		{channelName?: string; patientHashId?: string; activity?: AgoraCallActivity},
		{agoraChannel: AgoraChannel},
		Error
	>(),

	// Agora meeting get channel action
	getAgoraChannel: createAsyncAction(
		"agora/getAgoraChannel",
		"agora/getAgoraChannelSucceed",
		"agora/getAgoraChannelFailed",
	)<{channelId: string}, {agoraChannel: AgoraChannel}, Error>(),

	setRtcToken: createAction("agora/setRtcToken", (rtcToken: string) => ({rtcToken}))(),
};

export type AgoraActionType = ActionType<typeof agoraActions>;
