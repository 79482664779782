import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {referralClientActions} from "../redux/referralClientAction";
import {getSessionId} from "../../auth/helpers/authStorage";
import ReferralClientSDK from "../helper/referralClient";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";

const log = createLogger("getReferralTherapyResponse", SentryTags.ReferralClient);

export function* getReferralTherapyResponse(
	action: ActionType<typeof referralClientActions.getReferralTherapyResponse.request>,
) {
	const token = yield call(getSessionId);
	const {id} = action.payload;
	try {
		const res: Response = yield apiCallSaga(ReferralClientSDK.getReferralQuestionnaireResponseCSV, token, id);
		const contentDisposition = res.headers.get("content-disposition");
		const filename = contentDisposition
			.split(" ")
			.find((value) => value.includes("filename"))
			.replace("filename=", "");

		// download blob
		res.blob().then((blobFile) => {
			const url = window.URL.createObjectURL(blobFile);
			const csv = document.createElement("a");
			csv.href = url;
			csv.download = filename;
			// need to append the element to the dom -> otherwise it will not work in firefox
			document.body.appendChild(csv);
			csv.click();
			// remove the element again
			csv.remove();
		});

		yield put(referralClientActions.getReferralTherapyResponse.success());
	} catch (err) {
		log.captureException(err);
		yield put(referralClientActions.getReferralTherapyResponse.failure());
	}
}

export default function* () {
	yield takeEvery(getType(referralClientActions.getReferralTherapyResponse.request), getReferralTherapyResponse);
}
