import {AppState} from "redux/AppState";
import {createSelector} from "reselect";

const getAgoraState = (state: AppState) => state.agora;

export const getAgoraWindowAction = createSelector(getAgoraState, (state) => state.windowAction);

export const getAgoraRoomId = createSelector(getAgoraState, (state) => state.roomId);

export const isInvitationDialogOpen = createSelector(getAgoraState, (state) => state.isInvitationDialogOpen);

export const getInvitationLink = createSelector(getAgoraState, (state) => state.invitationLink);

export const getInvitationCreator = createSelector(getAgoraState, (state) => state.invitationCreator);

export const isAgoraWindowOpened = createSelector(getAgoraState, (state) => state.isAgoraWindowOpen);

export const isJoinWithVideo = createSelector(getAgoraState, (state) => state.isJoinWithVideo);

export const getChannelId = createSelector(getAgoraState, (state) => state.channelId);

export const getRtcToken = createSelector(getAgoraState, (state) => state.rtcToken);

export const getCallType = createSelector(getAgoraState, (state) => state.callType);
