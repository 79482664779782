import {takeEvery, put, call, take, cancelled, fork, takeLatest} from "redux-saga/effects";
import {StorageKeys} from "services/system/storage/StorageKeys";
import {bootstrapActions} from "./redux/bootstrapActions";
import {DISC} from "IoC/DISC";
import {AuthUser} from "../auth/authTypes";
import {authActions} from "../auth/redux";
import {getType} from "typesafe-actions";
import {clearAuthStorage} from "../auth/sagas/logoutSaga";
import {getAuthUserId, getSessionId, getSessionExpiryDate} from "../auth/helpers/authStorage";
import featureFlags from "../featureFlags/FeatureFlags";
import {eventChannel} from "redux-saga";
import analyticsService from "../analytics/AnalyticsService";
import {history} from "visual/App";
import {unidentifySentryUser} from "../errorHandler/sentryService";
import {RESET_APP_STATE} from "redux/StoreContainer";
import createLogger from "../logger/createLogger";
import {whenChatConnect} from "../chat/sagas/helpers";
import localization from "../localization/Localization";
import {getAuthUserData as fetchAuthUserData} from "../auth/helpers/getAuthUserData";
import waitForSortedContactsSaga from "./sagas/waitForSortedContactsSaga";
import {whenLoggedIn} from "../auth/sagas/helper";
import {NotificationAction} from "../notifications/redux/NotificationAction";
import {validateBrowserSaga} from "../warningBar/saga/warningBarSaga";
import {UserAccountPermissions} from "@sense-os/goalie-js";

const log = createLogger("bootstrapSaga");
/**
 * Bootstrap application data before rendering any components
 *
 * This saga is called right after we register the rootSaga to sagaMiddleware
 */
function* bootstrapApplication() {
	log.debug("bootstrapping application");
	log.addBreadcrumb({
		message: "bootstrapping application",
	});

	yield call(featureFlags.init);
	const authUser: AuthUser = yield call(initAuthUser);

	yield call(localization.init);

	if (authUser) {
		yield put(authActions.initLoggedInUser(authUser));
	}

	yield put(bootstrapActions.endBootstrapApp());
}

/**
 * Initialise some specific services when portal is logged in
 * These services requires token or authUser state to work
 *
 * TODO:
 * Initialise services in its own module and use whenLoggedIn saga instead
 */
function* initServicesWhenLoggedIn() {
	log.debug("portal is logged in, initialising some specific DISC services");
	log.addBreadcrumb({
		message: "initServicesWhenLoggedIn",
	});
	yield put(NotificationAction.init());

	// TODO : need to check it, and remove it ASAP
	yield call(DISC.getTrackingService().init);
	yield call(DISC.getScheduleService().init);
	yield call(DISC.getPlannedEventService().init);
	yield call(DISC.getSensorTargetService().init);

	// check is there any warning
	yield fork(validateBrowserSaga);
	yield put(authActions.fetchEmailVerificationStatus.request({}));
}

/**
 * Initialise video call service when chat is connected
 * (There is a mechanism in video call service `init` that needs to run whenever the chat is connected)
 *
 * TODO: Refactor to use saga instead
 */
function* initVideoCallServiceWhenChatIsConnected() {
	yield call(DISC.getVideoCallService().init);
}

/**
 * Reset some services to make sure portal doesn't do anything
 * when user is logged out.
 *
 * TODO:
 * Reset services in its own module and use whenLoggedOut saga instead
 */
function* reset() {
	log.debug("resetting some specific DISC services");
	yield call(unidentifySentryUser);
	yield call(DISC.getVideoCallService().reset);
	yield call(DISC.getScheduleService().reset);
	yield put(NotificationAction.reset());

	yield put({type: RESET_APP_STATE});
	history.push("/");
}

/**
 * Track route changes to segment analytics
 *
 * TODO: Move to analytics saga
 */
function* trackBrowserRouteChanges() {
	const chan = eventChannel<any>((emitter) => {
		history.listen((location) => {
			emitter(location);
		});
		return () => null;
	});

	while (true) {
		const location = yield take(chan);
		log.debug("route changed", location);
		yield call(analyticsService.trackCurrentPage);
	}
}

/**
 * Track localstorage changes, specifically for SESSION_ID (Which holds user's token),
 * and refresh the portal to make sure all tabs are in the same state (Logged in or logged out)
 */
function* processStorageEvent() {
	const chan = eventChannel<StorageEvent>((emitter) => {
		window.addEventListener("storage", emitter);
		return () => {
			window.removeEventListener("storage", emitter);
		};
	});

	try {
		while (true) {
			const event: StorageEvent = yield take(chan);
			const sessionId = yield call(getSessionId);
			const isLoggedIn = !!sessionId;
			if (event.key === StorageKeys.SESSION_ID) {
				// Force refresh whenever sessionId changed
				// to make sure all tabs are synchronised
				window.location.reload();
			}
			if (isLoggedIn && event.key === StorageKeys.SHOULD_JOIN_ORG) {
				window.location.reload();
			}
		}
	} finally {
		if (yield cancelled()) {
			chan.close();
		}
	}
}

function* initAuthUser() {
	const authUser: AuthUser = yield call(getAuthUserData);
	if (!authUser) {
		// If we couldn't get the authUser data, it's most likely that the token has expired.
		// Even if the portal couldn't reach the backend, it's best that we force the user
		// to logout.
		yield call(clearAuthStorage);
	} else {
		analytics.identify(authUser.externalId);
		yield put(authActions.updateAuthUser(authUser));

		const isPatient: boolean = !!authUser.roles.find((role) => role === UserAccountPermissions.PATIENT);
		if (isPatient) {
			yield put(bootstrapActions.waitForSortedContacts());
		}
	}
	return authUser;
}

/**
 * Populate AuthUser state with all the data that we have from storage
 * and userData from backend
 */
function* getAuthUserData() {
	const token = yield call(getSessionId);
	const userId = yield call(getAuthUserId);
	const tokenExpiryDate: Date | null = yield call(getSessionExpiryDate);
	const hasTokenExpired = tokenExpiryDate ? Date.now() > tokenExpiryDate.valueOf() : false;

	if (!token || hasTokenExpired) {
		return null;
	}

	try {
		const authUser = yield call(fetchAuthUserData, token, userId);
		return authUser;
	} catch (err) {
		log.captureException(err);
	}
	return null;
}

export default function* () {
	yield fork(processStorageEvent);
	yield fork(trackBrowserRouteChanges);
	yield takeEvery(getType(bootstrapActions.startBootstrapApp), bootstrapApplication);
	yield takeEvery(
		[getType(authActions.initLoggedInUser), getType(authActions.login.success)],
		initServicesWhenLoggedIn,
	);
	yield takeLatest([getType(authActions.logout.success), getType(authActions.logout.failure)], reset);
	yield fork(whenLoggedIn(whenChatConnect(initVideoCallServiceWhenChatIsConnected)));
	yield fork(waitForSortedContactsSaga);
}
